import React from 'react'

import logoDesktop from '../assets/images/cover.jpg';
import logoMobile from '../assets/images/MBproductions_black.png';

const Header = (props) => (
    <header id="header" className="alt">
        <h1 className="logo"><img src={logoDesktop} id="logoDesktop" alt="MB productions" /><img src={logoMobile} id="logoMobile" alt="MB productions" /></h1>
        <p>Mixed Beats? More Bass! We're a collective of DJs, promoters and otherwise talented music lovers with deep connections to the Newcastle scene who support new talent, positive dance-floor vibes, and music variety!</p>
    </header>
)

export default Header
