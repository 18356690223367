import { Link, graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import moment from 'moment-timezone'

moment.tz.setDefault('Australia/Sydney')

class Index extends React.Component {
  constructor({ data, location, ...props }) {
    super(props)
    this.state = {
      stickyNav: false,
    }
    this.data = data
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    const events = this.data.allMarkdownRemark.edges
    const futureEvents = events.filter(e => moment().isBefore(e.node.frontmatter.startTime))
    const currentEvents = events.filter(e => moment().isBefore(e.node.frontmatter.endTime) && moment().isAfter(e.node.frontmatter.startTime))
    const current = currentEvents[0] && currentEvents[0].node
    const future = futureEvents[0] && futureEvents[0].node
    const currentIsReal = current && current.frontmatter.stream
    const futureIsReal = !!(future && !future.frontmatter.stream)

    const previous = events.filter(e => !e.node.frontmatter.endTime || moment().isAfter(e.node.frontmatter.endTime))
    return (
      <Layout>
        <Helmet title="MB productions" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />
        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  {current || future ?
                    <h2>It's party time!</h2>
                    : <h2>And we're back in the real world!</h2>}
                </header>
                {currentIsReal ?
                  <div>
                    <Link to="/live-stream">
                      <h2>
                        Live event on now: <b>{current.frontmatter.title} {current.frontmatter.stream && <span className="icon fa-podcast"></span>}</b>
                      </h2>
                      <p>
                        {moment(current.frontmatter.startTime).format("DD MMMM YYYY")} @ {moment(current.frontmatter.startTime).format("hh:mm a")} - {moment(current.frontmatter.endTime).format("hh:mm a")} ({moment(current.frontmatter.startTime).format("z")})
                      </p>
                    </Link>
                  </div>
                  : future ?
                    <div>
                      <p>
                        Click on events below for more info or to buy tickets.
                      </p>
                      <h3>
                        Next event
                      </h3>
                      <h4>
                        <a href={future.frontmatter.link} target="mixbfb">
                          {moment(future.frontmatter.startTime).format("DD MMMM YYYY")} @ {moment(future.frontmatter.startTime).format("hh:mm a")} - {moment(future.frontmatter.endTime).format("hh:mm a")} ({moment(future.frontmatter.startTime).format("z")}): <b>{future.frontmatter.title} {future.frontmatter.stream && <span className="icon fa-podcast"></span>}</b>
                        </a>
                      </h4>

                    </div>
                    : <div>
                      <p>
                        Check below for more information on our events.
                      </p>
                    </div>
                }
              </div>
              {!futureIsReal &&
                <Link to="/live-stream" className="icon major fa-podcast pointer">
                </Link>
              }
              {futureIsReal &&
                <a href={future.frontmatter.link} className="icon major fa-ticket pointer">
                </a>
              }
            </div>
            {current && current.frontmatter.stream &&
              <Link to="/live-stream" className="no-decoration">
                <img src={current.frontmatter.bannerImage} alt="Event banner" style={{ "width": "100%" }} />
              </Link>
            }
            {!current && future &&
              <a href={future.frontmatter.link} target="mixbfb" className="no-decoration">
                <img src={future.frontmatter.bannerImage} alt="Event banner" style={{ "width": "100%" }} />
              </a>
            }
            <div className="right">
              <ul className="actions">
                {!current && future &&
                  <li>
                    <a href={future.frontmatter.link} target="mixbfb" className="button">
                      tickets &nbsp;<span className="icon icon-2x fa-ticket"></span>
                    </a>
                  </li>}
                {!futureIsReal &&
                  <li>
                    <Link to="/live-stream" className="button">
                      tune in to the live stream <span className="icon fa-podcast"></span>
                    </Link>
                  </li>
                }
                {!futureIsReal &&
                  <li>
                    <Link to="/live-stream-vj" className="button">
                      tune in to the vj stream <span className="icon fa-podcast"></span>
                    </Link>
                  </li>
                }
              </ul>
            </div>
            <div className="right">
              <div>
              </div>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Past events</h2>
            </header>
            <ul className="features">
              {previous.map(({ node }, index) => {
                const { fields, frontmatter } = node
                const { date, startTime } = fields
                const startDate = startTime ? moment(startTime).format("DD MMMM YYYY") : moment.utc(date).format("DD MMMM YYYY")
                const { title, link, location, stream } = frontmatter
                return <li>
                  <span className={`icon major style${(index % 5) + 1} fa-calendar`}></span>
                  <h3>{title} {stream && <span className="icon fa-podcast"></span>}</h3>
                  <a href={link} target="mixbfb"><span className="icon fa-facebook-square"></span>&nbsp; {startDate}</a>
                  {!stream && <p class="sub">{location}</p>}
                  {stream && <p class="sub">Online event</p>}
                </li>
              })}
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a className="button" target="mixbfb" href="https://www.facebook.com/pg/MixBproductions/events/">More events</a>
                </li>
              </ul>
            </footer>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Vital statistics</h2>
              <p>
                Our dancefloor scientists have been measuring our events.&nbsp;
                <br />
                We have some high quality statistics available here for your investigational pleasure.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-calendar"></span>
                <strong>{previous.length}</strong> Past events
              </li>
              <li className="style2">
                <span className="icon fa-podcast"></span>
                <strong>15+</strong> Live streams
              </li>
              <li className="style3">
                <span className="icon fa-heartbeat"></span>
                <strong>120-180</strong> Beats per minute
              </li>
              <li className="style4">
                <span className="icon fa-sign-language"></span>
                <strong>1000+</strong> Dance moves (*)
              </li>
              <li className="style5">
                <span className="icon fa-smile-o"></span>
                <strong>2000+</strong> Dancefloor smiles
              </li>
            </ul>
            <p className="sub">
              (*) of the funky variety
            </p>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="https://www.facebook.com/MixBproductions" className="button" target="mixbfb">
                    Follow us &nbsp;<span className="icon icon-2x fa-facebook-square"></span>
                  </a>
                </li>
              </ul>
            </footer>
          </section>
          {/*
          <section id="cta" className="main special">
            <header className="major">
              <h2>Congue imperdiet</h2>
              <p>
                Donec imperdiet consequat consequat. Suspendisse feugiat congue
                <br />
                posuere. Nulla massa urna, fermentum eget quam aliquet.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button special">
                    Get Started
                  </Link>
                </li>
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>
           */}
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [fields___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            link
            stream
            location
            startTime
            endTime
            bannerImage
          }
          fields {
            date
          }
        }
      }
    }
  }
`